import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";

export const RedefinirSenha = () => {
    return (
        <div className="w-full h-screen pt-8 pb-12 bg-white flex-col justify-end py-2 px-6 gap-8 flex">
            <div className="flex-col justify-start  gap-8 inline-flex">
                <div className="flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch pb-8 flex-col gap-6 justify-center items-start flex">
                        <img src={logo} alt="vortex" className="w-[60px] h-[54px] relative  rotate-180" />
                        <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                            <div className="text-[#1d2838] text-[40px] font-black flex flex-col leading-[50px]">
                                Recuperar acesso
                            </div>
                            <div className="self-stretch justify-start items-center gap-1 inline-flex">
                                <div className="grow shrink basis-0 text-[#475466] text-sm font-normal leading-[21px]">Siga os passos para recuperar seu acesso</div>
                            </div>
                        </div>
                    </div>
                    <div className="self-stretch rounded-xl flex-col justify-start items-center gap-6 flex">
                        <div className="self-stretch flex-col justify-start items-start gap-5 flex">
                            <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                                <label className="self-stretch h-6 text-[#1d2838] text-sm font-medium leading-[21px]">Email</label>
                                <input type="email" className="self-stretch h-14 p-4 bg-[#fefefe] rounded-md border border-[#e3e4e8] justify-start items-center gap-2 inline-flex" />
                            </div>
                        </div>
                        <div className="self-stretch h-12 flex-col justify-start items-start gap-4 flex">
                            <div className="self-stretch h-12 flex-col justify-start items-start gap-3 flex">
                                <div className="self-stretch h-12 px-6 py-3 bg-[#156fee] rounded-lg shadow justify-center items-center gap-2 inline-flex">
                                    <div className="text-[#fefefe] text-sm font-medium leading-[21px]">Recuperar acesso</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="self-stretch justify-center items-start gap-1 inline-flex">
                    <div className="text-[#1d2838] text-sm font-normal leading-[21px]">Ainda não tem uma coisa?</div>
                    <div className="justify-start items-start flex">
                        <div className="justify-center items-center gap-2 flex">
                            <Link to="/login" className="text-[#156fee] text-sm font-semibold leading-tight">
                            Crie uma aqui
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}