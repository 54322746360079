import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Home } from './pages/home';
import { Login } from './pages/login';
import { useState, useEffect } from 'react';
import './App.css'; // Arquivo de CSS com as animações
import { RedefinirSenha } from './pages/login/redefinirsenha';

function AnimatedRoutes() {
    const location = useLocation();
    const [animate, setAnimate] = useState(true);

    useEffect(() => {
        setAnimate(true); // Ativa a animação ao trocar de rota
    }, [location]);

    return (
        <div className={`page ${animate ? 'animate-in' : 'animate-out'}`}>
            <Routes location={location}>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/redefinir-senha" element={<RedefinirSenha />} />
            </Routes>
        </div>
    );
}

export default function App() {
    return (
        <BrowserRouter basename="/">
            <AnimatedRoutes />
        </BrowserRouter>
    );
}
