import { HeaderComponent } from "../../components/header"
import { ButtonComponent } from "../../components/button";
import menuIcon from "../../assets/menu.svg";
import underlineImage from "../../assets/underline.svg";
import galleryImage from "../../assets/_galleryFeaturesBase.svg";
import mobileIcon from "../../assets/Mobile.svg";
import houseGroupIcon from "../../assets/houseGroup.svg";
import checkedIcon from "../../assets/checked.svg";
import rectagle145Image from "../../assets/rectangle145.svg";
import rectagle146Image from "../../assets/rectangle146.svg";
import rectagle147Image from "../../assets/rectangle147.svg";
import rectagle148Image from "../../assets/rectangle148.svg";
import { CardSectionComponent } from "./card-section";
import { CardSectionTowComponent } from "./card-section-tow";
import addCircleIcon from "../../assets/addCircle.svg";
import instagramIcon from "../../assets/instagram.svg";
import facebookIcon from "../../assets/facebook.svg";
import twitterIcon from "../../assets/twitter.svg";
import linkedinIcon from "../../assets/linkedin.svg";
import emailIcon from "../../assets/email.svg";
import phoneIcon from "../../assets/phone.svg";
import locationIcon from "../../assets/location.svg";

import logo from "../../assets/logo.svg";
import { useState } from "react";
import { Sidebar } from "../../components/sidebar";
export const Home = () => {
    // Estado para controlar a visibilidade do drawer
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

    // Função para abrir e fechar o drawer
    const toggleDrawer = () => {
        setDrawerOpen((prevState) => !prevState);
    };

    return (
        <div className="h-screen w-full bg-[#f8f9fb]">
            <HeaderComponent>
                <div className="flex gap-4 justify-center items-center">
                    <button
                        data-drawer-target="drawer-navigation"
                        data-drawer-toggle="drawer-navigation"
                        aria-controls="drawer-navigation"
                        onClick={toggleDrawer}>
                        <img src={menuIcon} alt="vortex" className="w-6 h-6 relative" />
                    </button>
                    <span className="font-semibold text-2xl text-[#1D2939]">Vortéx</span>
                </div>
                <ButtonComponent>
                    Quero vender
                </ButtonComponent>
                <Sidebar id="side-menu" isOpen={isDrawerOpen} closeSidebar={toggleDrawer}/>
            </HeaderComponent >

            <div className="section flex flex-col mt-[72px] gap-8 h-full ">

                <div className="flex-col py-2 px-6 justify-start items-start gap-8 inline-flex bg-[#fefefe]">
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className="self-stretch text-[#141c24] text-[40px] font-light leading-[45px] relative">
                            Tudo que você precisa para<br />vender seu carro
                            <img src={underlineImage} alt="underline" className="absolute bottom-[-5px] left-0 w-auto h-auto" />
                        </div>
                        <div className="self-stretch text-[#475466] text-xl font-normal leading-[30px]">
                            Lorem ipsum dolor sit amet consectetur. Nulla et velit et odio leo amet fringilla ut quisque
                        </div>
                    </div>
                    <img alt="" className="w-[382px] h-[380px] relative rounded-lg" src={galleryImage} />
                    <div className="self-stretch h-14 flex-col justify-start items-start gap-3 flex">
                        <ButtonComponent size="full">
                            Quero vender meu carro
                        </ButtonComponent>
                    </div>
                    <div>{''}</div>
                </div>

                <div className="flex-col flex gap-4 py-2 px-6">
                    {/* Card Comodidade */}
                    <div className="flex-col justify-start items-center gap-4 inline-flex">
                        <div className="self-stretch h-[116px] flex-col justify-start items-center gap-3 flex">
                            <div className="px-2.5 py-0.5 bg-[#eff5ff] rounded-[150px] justify-center items-center gap-2.5 inline-flex">
                                <div className="text-center text-[#156fee] text-sm font-semibold leading-tight">Comodidade</div>
                            </div>
                            <div className="self-stretch text-center text-[#0f1728] text-3xl font-semibold leading-10">Como o processo todo funciona</div>
                        </div>
                        <div className="self-stretch text-center text-[#475466] text-lg font-normal">Receba as melhores ofertas se conectando com concessionárias em um só lugar.</div>
                    </div>
                    {/* Card Vendedor */}
                    <CardSectionComponent
                        icon={mobileIcon}
                        titulo="O vendedor cria um anúncio detalhado"
                        descricao="O vendedor vai preencher os dados do veículo, fotos e valor desejado pelo seu veículo"></CardSectionComponent>

                    {/* Card Concessionária */}
                    <CardSectionComponent
                        icon={houseGroupIcon}
                        titulo="Concessionárias encontram o carro ideal"
                        descricao="Você não tem mais esforços aqui. Nós buscaremos as concessionárias com a melhor oferta que você desejar."></CardSectionComponent>

                    {/* Card Negociação */}
                    <CardSectionComponent
                        icon={checkedIcon}
                        titulo="Negociação mediada por nós"
                        descricao="Toda mediação entre os valores ofertados são mediados por nós, para sua total segurança e comodidade."></CardSectionComponent>

                    {/* Gallery */}
                    <div className="self-stretch h-[360px] flex-col justify-start items-center gap-6 flex">
                        <div className="self-stretch h-[360px] py-4 flex-col justify-center items-center flex">
                            <div className="self-stretch h-[328px] justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-4 inline-flex">
                                    <img alt="rectangle" className="self-stretch h-[202px] rounded-[15px]" src={rectagle145Image} />
                                    <img alt="rectangle" className="self-stretch h-[110px] rounded-[15px]" src={rectagle148Image} />
                                </div>
                                <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-4 inline-flex">
                                    <img alt="rectangle" className="self-stretch h-[110px] rounded-[15px]" src={rectagle147Image} />
                                    <img alt="rectangle" className="self-stretch h-[202px] rounded-[15px]" src={rectagle146Image} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Newsletter */}
                <div className="gap-4 bg-[#eff8ff] flex-col justify-start items-center inline-flex">
                    <div className="self-stretch flex-col justify-start items-start gap-6 flex py-2 px-6">
                        <div className="self-stretch flex-col justify-start items-start gap-4 pt-4 flex">
                            <div className="self-stretch text-[#194084] text-[28px] font-semibold leading-9">Cadastre sue e-mail e fique por dentro de novidades</div>
                            <div className="self-stretch text-[#344053] text-base font-normal leading-normal">Fique por dentro das informações mais relevantes, nossas novidades, notícias e ofertas especiais!</div>
                        </div>
                        <div className="self-stretch flex-col justify-start items-end gap-3 flex">
                            <div className="self-stretch h-14 pl-3 pr-2 py-2.5 bg-[#fefefe] rounded-lg border border-[#e3e4e8] justify-start items-center gap-2 inline-flex">
                                <div className="grow shrink basis-0 h-6 justify-start items-center gap-1 flex">
                                    <div className="grow shrink basis-0 text-[#475466] text-base font-normal leading-normal">Seu e-mail</div>
                                </div>
                            </div>
                            <ButtonComponent size="full">
                                Quero vender meu carro
                            </ButtonComponent>
                        </div>
                    </div>
                    <div>{''}</div>
                </div>

                {/* Proque Confinça */}
                <div className="flex-col flex gap-4 py-2 px-6">
                    {/* Card Confinça */}
                    <div className="flex-col justify-start items-center gap-4 inline-flex">
                        <div className="self-stretch h-[116px] flex-col justify-start items-center gap-3 flex">
                            <div className="h-6 px-2.5 py-0.5 bg-[#eff5ff] rounded-[150px] justify-center items-center gap-2.5 inline-flex">
                                <div className="text-center text-[#156fee] text-sm font-semibold leading-tight">Confiança</div>
                            </div>
                            <div className="self-stretch text-center text-[#0f1728] text-3xl font-semibold leading-10">Por que usar nosso sistema?</div>
                        </div>
                    </div>

                    {/* Card Para vendedores e Para concessionárias  */}
                    <CardSectionTowComponent />
                </div>

                {/* FAQ */}
                <div className="h-[1042px] px-6 py-8 bg-[#fefefe] flex-col justify-start items-center inline-flex">
                    <div className="self-stretch h-[978px] flex-col justify-start items-center gap-6 flex">
                        <div className="self-stretch h-60 py-6 flex-col justify-center items-center gap-4 flex">
                            <div className="self-stretch h-[104px] flex-col justify-start items-center gap-3 flex">
                                <div className="self-stretch h-[104px] flex-col justify-start items-center gap-3 flex">
                                    <div className="px-2.5 py-0.5 bg-[#eff5ff] rounded-[150px] justify-center items-center gap-2.5 inline-flex">
                                        <div className="text-center text-[#156fee] text-sm font-semibold leading-tight">FAQ</div>
                                    </div>
                                    <div className="self-stretch text-center text-[#1d2838] text-[28px] font-semibold">Perguntas que sempre nos fazem</div>
                                </div>
                            </div>
                            <div className="self-stretch text-center text-[#475466] text-base font-normal leading-normal">Tentamos responder às perguntas mais comuns. Se tiver alguma dúvida adicional, entre em contato com nossa equipe amigável.</div>
                        </div>
                        <div className="self-stretch h-[432px] flex-col justify-start items-start gap-3 flex">
                            <div className="self-stretch p-4 rounded-lg border border-[#e3e4e8] justify-start items-center inline-flex">
                                <div className="grow shrink basis-0 h-7 justify-start items-center gap-4 flex">
                                    <div className="grow shrink basis-0 text-[#1d2838] text-lg font-medium leading-7">01. Lorem ipsum?</div>
                                    <div className="w-6 h-6 relative">
                                        <img alt="" src={addCircleIcon} />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch p-4 rounded-lg border border-[#e3e4e8] justify-start items-center inline-flex">
                                <div className="grow shrink basis-0 h-14 justify-start items-center gap-4 flex">
                                    <div className="grow shrink basis-0 text-[#1d2838] text-lg font-medium leading-7">02. Consectetur et ac vivamus blandit?</div>
                                    <div className="w-6 h-6 relative">
                                        <img alt="" src={addCircleIcon} />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch p-4 rounded-lg border border-[#e3e4e8] justify-start items-center inline-flex">
                                <div className="grow shrink basis-0 h-7 justify-start items-center gap-4 flex">
                                    <div className="grow shrink basis-0 text-[#1d2838] text-lg font-medium leading-7">03. Rhoncus ut elementum enim?</div>
                                    <div className="w-6 h-6 relative">
                                        <img alt="" src={addCircleIcon} />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch p-4 rounded-lg border border-[#e3e4e8] justify-start items-center inline-flex">
                                <div className="grow shrink basis-0 h-14 justify-start items-center gap-4 flex">
                                    <div className="grow shrink basis-0 text-[#1d2838] text-lg font-medium leading-7">04. Enim egt ut pellentesque amet malesuada?</div>
                                    <div className="w-6 h-6 relative">
                                        <img alt="" src={addCircleIcon} />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch p-4 rounded-lg border border-[#e3e4e8] justify-start items-center inline-flex">
                                <div className="grow shrink basis-0 h-14 justify-start items-center gap-4 flex">
                                    <div className="grow shrink basis-0 text-[#1d2838] text-lg font-medium leading-7">05. Lorem ipsum dolor sit amet consectetur?</div>
                                    <div className="w-6 h-6 relative">
                                        <img alt="" src={addCircleIcon} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="self-stretch h-[258px] p-4 bg-[#eff8ff] rounded-[20px] flex-col justify-start items-center gap-8 flex">
                            <div className="self-stretch h-[138px] flex-col justify-start items-center gap-3 flex">
                                <div className="text-center text-[#194084] text-xl font-normal leading-[30px]">Ainda tem dúvidas?</div>
                                <div className="self-stretch text-center text-[#194084] text-sm font-normal leading-normal">Desculpe por não tirar todas as dúvidas que esteja procurando aqui. Por favor, entre em contato conosco e estaremos felizes em te ajudar.</div>
                            </div>
                            <div className="self-stretch h-14 flex-col justify-start items-start gap-3 flex">
                                <div className="self-stretch h-14 px-6 py-4 bg-[#156fee] rounded-lg shadow justify-center items-center gap-2 inline-flex">
                                    <div className="text-[#fefefe] text-sm font-medium leading-[21px]">Nos chame no WhatsApp</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <div className="px-6 flex-col justify-start items-center gap-8 inline-flex">
                    <div className="self-stretch h-44 flex-col justify-start items-center gap-8 flex">
                        <div className="self-stretch h-28 flex-col justify-start items-center gap-6 flex">
                            <div className="self-stretch justify-center items-center gap-2 inline-flex">
                                <img src={logo} alt="vortex" className="w-11 h-10" />
                                <div className="text-center text-[#1d2838] text-2xl font-semibold leading-[28.80px]">Vortéx</div>
                            </div>
                            <div className="self-stretch text-center text-[#475466] text-base font-normal leading-normal">Adoramos trabalhar com pessoas.<br />Vamos construir algo grande juntos.</div>
                        </div>
                        <div className="self-stretch justify-center items-center gap-4 inline-flex">
                            <div className="w-8 h-8 relative">
                                <img src={instagramIcon} alt="instagram" className="w-8 h-8 left-[2px] top-[2px] absolute bg-[#98a1b2] rounded-md" />
                            </div>
                            <div className="w-8 h-8 relative">
                                <img src={facebookIcon} alt="linkedin" className="w-8 h-8 left-[2px] top-[2px] absolute bg-[#98a1b2] rounded-full" />
                            </div>
                            <div className="w-8 h-8 relative">
                                <img src={twitterIcon} alt="twitter" className="w-8 h-8 left-[2px] top-[2px] absolute" />
                            </div>
                            <div className="w-8 h-8 relative">
                                <img src={linkedinIcon} alt="linkedin" className="w-8 h-8 left-[2px] top-[2px] absolute bg-[#98a1b2] rounded-full" />
                            </div>
                        </div>
                    </div>
                    <div className="self-stretch h-[172px] flex-col justify-start items-start gap-8 flex">
                        <div className="self-stretch h-[172px] flex-col justify-start items-start gap-4 flex">
                            <div className="text-center text-[#1d2838] text-lg font-semibold font-['Inter'] leading-7">Fale conosco</div>
                            <div className="self-stretch h-32 flex-col justify-center items-start gap-4 flex">
                                <div className="self-stretch rounded-lg border-[#ced2da] justify-center items-center gap-2 inline-flex">
                                    <img src={emailIcon} alt="email" className="w-5 h-5 relative" />
                                    <div className="grow shrink basis-0 text-[#475466] text-base font-normal leading-normal">cafezinho@vortex.com.br</div>
                                </div>
                                <div className="self-stretch rounded-lg border-[#ced2da] justify-center items-center gap-2 inline-flex">
                                    <img src={phoneIcon} alt="phone" className="w-5 h-5 relative" />
                                    <div className="grow shrink basis-0 text-[#475466] text-base font-normal leading-normal">+55 (11) 9.0000-0000</div>
                                </div>
                                <div className="self-stretch rounded-lg border-[#ced2da] justify-start items-start gap-2 inline-flex">
                                    <img src={locationIcon} alt="location" className="w-5 h-5 relative" />
                                    <div className="grow shrink basis-0 text-[#475466] text-base font-normal leading-normal">Av. Dr. Lorem Ipusm, 5000 • Vila Andrade • São Paulo/SP</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-px relative bg-[#e3e4e8]" />
                    <div className="self-stretch h-[60px] flex-col justify-start items-center gap-3 flex">
                        <div className="text-[#1d2838] text-base font-normal leading-normal">© 2024 Vortéx • Todos os direitos reservados</div>
                        <div className="justify-start items-center gap-4 inline-flex">
                            <div className="text-right text-[#156fee] text-base font-normal font-['Inter'] underline leading-normal">Termos de uso</div>
                            <div className="text-right text-[#156fee] text-base font-normal font-['Inter'] underline leading-normal">Política de privacidade</div>
                        </div>
                    </div>
                    <div>{''}</div>
                </div>
            </div>
        </div>
    )
}