import React, { useRef, type ComponentProps } from "react";
import user from "../assets/essentials-user-box.svg";
import groupIcon from "../assets/group.svg";
import documentCheckIcon from "../assets/document-check.svg";
import questionIcon from "../assets/question.svg";
import startCheckedIcon from "../assets/start-checked.svg";
import closeIcon from "../assets/close.svg";
import logo from "../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { tv, VariantProps } from 'tailwind-variants'
import { HeaderComponent } from "./header";

const aVariants = tv({
    base: 'self-stretch flex flex-row gap-4 items-center justify-between',

    variants: {
        variant: {
            primary: 'border-b border-[#E3E4E8]',
            secondary: 'border-none',
        },
        size: {
            default: 'py-4',
        }
    },

    defaultVariants: {
        variant: 'primary',
        size: 'default'
    }
})

interface SidebarItemProps extends ComponentProps<'a'>, VariantProps<typeof aVariants> {
    icon: string;
    title: string;
    description?: string;
    to: string;
}

const SidebarItem = ({ icon, title, description, to, variant, size, ...props }: SidebarItemProps) => {
    return (
        <Link to={to} {...props} className={aVariants({ variant, size })}>
            <div className="flex flex-row gap-3 items-center">
                <img src={icon} alt="icon" className="w-6 h-6 relative flex-col justify-start items-start inline-flex" />
                <div className="flex flex-col">
                    <span className="text-[#1D2939] text-[16px] font-[400]">{title}</span>
                    <span className="text-[#475467] text-[12px] font-[400]">{description}</span>
                </div>
            </div>
            <img src={groupIcon} alt="groupIcon" className="w-6 h-6 py-2" />
        </Link>
    )
}

interface SideMenuProps {
    id: string;
    isOpen: boolean;
    closeSidebar?: () => void;
}

export const Sidebar: React.FC<SideMenuProps> = ({ id, isOpen, closeSidebar }) => {
    const navigate = useNavigate();

    const asideRef = useRef<HTMLDivElement>(null);
    return (
        <aside ref={asideRef} id={id} className={`bg-[#fefefe] fixed top-0 left-0 z-40 w-full h-screen py-2 px-6 transition-transform ${isOpen ? '' : '-translate-x-full'}  sm:translate-x-0`} aria-label="Sidebar">
            <div className="h-screen w-full">
                {/* Header */}
                <HeaderComponent>
                    <div className="flex gap-3">
                        <img src={logo} alt="vortex" className="w-[35px] max-w-[35px] h-[32px] max-h-8" />
                        <span className="font-semibold text-2xl text-[#1D2939]">Vortéx</span>
                    </div>
                    <button onClick={closeSidebar}>
                        <img src={closeIcon} alt="groupIcon" className="w-6 max-w-6 h-6 max-h-6 " />
                    </button>
                </HeaderComponent>

                {/* Sidebar */}
                <div className="flex flex-col gap-3 mt-[72px] overflow-y-auto h-full">
                    <SidebarItem icon={user} title="Login" description="Visualizar dados do perfil" to="/login" />
                    <SidebarItem icon={documentCheckIcon} title="Termos de Uso" description="Sobre o App" to="" />
                    <SidebarItem icon={documentCheckIcon} title="Privacidade" description="Sobre o App" to="" />
                </div>

                <div className="fixed bottom-0 flex flex-col gap-3 w-full left-0 py-2 px-6">
                    <SidebarItem icon={questionIcon} title="Ajuda" to="" />
                    <SidebarItem variant="secondary" icon={startCheckedIcon} title="Avaliações" to="" />
                </div>
            </div>
        </aside>
    )
}