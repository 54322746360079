import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { ButtonComponent } from "../../components/button";
import { Loading } from "../../components/loading";
import { useState } from "react";
export const Login = () => {
    const navigator = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handlerSubmit = () => {
        setIsLoading(true);
        setTimeout(() => {
            navigator('/')
        }, 2000);
    }

    if (isLoading) {
        return <Loading />
    }

    return (

        <div className="w-full h-screen pt-8 pb-12 bg-white flex-col justify-end py-2 px-6 gap-8 flex">
            <div className="flex-col justify-start  gap-8 inline-flex">
                <div className="flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch pb-8 flex-col gap-6 justify-center items-start flex">
                        <img src={logo} alt="vortex" className="w-[60px] h-[54px] relative  rotate-180" />
                        <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                            <div className="text-[#1d2838] text-[40px] font-black flex flex-col leading-[50px]">
                                Bem-vindo ao
                                <span className="text-blue-600 text-[40px] font-black">Vortéx</span>
                            </div>
                            <div className="self-stretch justify-start items-center gap-1 inline-flex">
                                <div className="grow shrink basis-0 text-[#475466] text-sm font-normal leading-[21px]">Insira suas informações para acessar sua conta</div>
                            </div>
                        </div>
                    </div>
                    <div className="self-stretch rounded-xl flex-col justify-start items-center gap-6 flex">
                        <div className="self-stretch flex-col justify-start items-start gap-5 flex">
                            <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                                <label className="self-stretch h-6 text-[#1d2838] text-sm font-medium leading-[21px]">Email</label>
                                <input type="email" className="self-stretch h-14 p-4 bg-[#fefefe] rounded-md border border-[#e3e4e8] justify-start items-center gap-2 inline-flex" />
                            </div>
                            <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                                <label className="self-stretch h-6 text-[#1d2838] text-sm font-medium leading-[21px]">Senha</label>
                                <input type="password" className="self-stretch h-14 p-4 bg-[#fefefe] rounded-md border border-[#e3e4e8] justify-start items-center gap-2 inline-flex" />
                            </div>
                        </div>
                        <div className="self-stretch justify-between items-center inline-flex">
                            <div className="justify-start items-center gap-2 flex">
                                <div className="w-5 h-5 justify-center items-center flex">
                                    {/* Input checkbox */}
                                    <input
                                        type="checkbox"
                                        className="w-5 h-5 relative rounded-lg border-2 border-[#e3e4e8] cursor-pointer"
                                        id="rememberMe"
                                    />
                                </div>
                                <label htmlFor="rememberMe" className="text-[#1d2838] text-sm font-medium leading-tight cursor-pointer">
                                    Permanecer conectado
                                </label>
                            </div>

                            <div className="justify-center items-center gap-2 flex">
                                <Link to="/redefinir-senha" className="text-[#156fee] text-sm font-semibold leading-tight">
                                    Esqueci a senha
                                </Link>
                            </div>
                        </div>
                        <div className="self-stretch h-12 flex-col justify-start items-start gap-4 flex">
                            <div className="self-stretch h-12 flex-col justify-start items-start gap-3 flex">
                                <div className="self-stretch h-12 px-6 py-3 bg-[#156fee] rounded-lg shadow justify-center items-center gap-2 inline-flex">
                                    <ButtonComponent onClick={handlerSubmit}>
                                        <div className="text-[#fefefe] text-sm font-medium leading-[21px]">Login</div>
                                    </ButtonComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="self-stretch justify-center items-start gap-1 inline-flex">
                    <div className="text-[#1d2838] text-sm font-normal leading-[21px]">Ainda não tem uma coisa?</div>
                    <div className="justify-start items-start flex">
                        <div className="justify-center items-center gap-2 flex">
                            <div className="text-[#156fee] text-sm font-medium underline leading-[21px]">Crie uma aqui</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}