import type { ComponentProps, ReactNode } from "react";
import { tv, VariantProps } from 'tailwind-variants'

const buttonVariants = tv({
    base: 'rounded-lg shadow justify-center items-center gap-2 inline-flex',
    variants: {
        variant: {
            primary: 'bg-[#156fee] text-[#fefefe] text-sm font-medium leading-[21px]',
            secondary: '',
        },
        size: {
            default: 'h-12 px-6 py-3',
            full: 'self-stretch h-14 px-6 py-4'
        }
    },

    defaultVariants: {
        variant: 'primary',
        size: 'default'
    }
})

interface ButtonProps extends ComponentProps<'button'>, VariantProps<typeof buttonVariants> {
    children: ReactNode,
}

export const ButtonComponent = ({ children, variant, size, ...props }: ButtonProps) => {
    return (
        <button {...props} className={buttonVariants({ variant, size })}>
            {children}
        </button>
    )
}